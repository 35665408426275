import { Component, HostListener, OnInit } from "@angular/core";
import { GlobalConstanst } from "../../common/global";

@Component({
  selector: "app-navmenu",
  templateUrl: "./navmenu.component.html",
  styleUrls: ["./navmenu.component.css"],
})
export class NavmenuComponent implements OnInit {
  menu = GlobalConstanst.menu;

  constructor() {}

  ngOnInit(): void {
    this.updateActiveSection();
  }

  @HostListener("window:scroll", [])
  onScroll(): void {
    this.updateActiveSection();
  }

  updateActiveSection(): void {
    const scrollPosition = window.scrollY + window.innerHeight * 0.4;
    this.menu.forEach((m) => {
      const section = document.getElementById(m.id);
      if (section) {
        const rect = section.getBoundingClientRect();
        const sectionTop = rect.top + window.scrollY;
        const sectionBottom = sectionTop + section.offsetHeight;
        m.active =
          scrollPosition >= sectionTop && scrollPosition <= sectionBottom;
      }
    });
  }

  isActive(id: string): boolean {
    return this.menu.find((m) => m.id === id)?.active || false;
  }
}
