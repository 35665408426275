import { Component, OnInit, AfterViewInit, ElementRef } from "@angular/core";

@Component({
  selector: "app-kapcsolat",
  templateUrl: "./tovabbi.component.html",
  styleUrls: ["./tovabbi.component.css"],
})
export class TovabbiComponent implements OnInit, AfterViewInit {
  titlefromParent = "Kérdése van?";
  workers = [
    {
      name: "Fekete Zsolt",
      pos: "ügyvezető",
      email: "feketezs@netfaktor.hu",
      phone: "+36 20/495 1395",
    },
  ];

  constructor(private el: ElementRef) {}

  ngOnInit() {}

  ngAfterViewInit() {
    const elements = this.el.nativeElement.querySelectorAll(".fade-up");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    elements.forEach((el: Element) => observer.observe(el));
  }
}
