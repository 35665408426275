<body>
  <div>
    <div class="pad">
      <h2 style="text-align: center; color: #0033cc">
        <strong class="fade-up">ELADÓKNAK</strong>
      </h2>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        "
      >
        <p
          class="fade-up"
          style="
            padding-bottom: 5px;
            text-align: center;
            width: 70%;
            color: 'black';
          "
        >
          Eladó lehet minden jogi és magánszemély, aki lejárt vagy nem lejárt
          követeléssel rendelkezik és azt értékesíteni kívánja.
        </p>
      </div>

      <!-- <p style="text-align: center"><strong>Eladók:</strong></p> -->

      <div
        class="icon-container fade-up"
        style="grid-template-columns: 5fr 5fr; column-gap: 5px"
      >
        <div
          style="
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
          "
        >
          <div class="sepcial-pad">
            <div class="row">
              <div class="column">
                <div class="icon-box">
                  <div class="iconbox-icon-wrap">
                    <span class="iconbox-icon-container">
                      <img
                        class="hover-enlarge"
                        src="../../../assets/img/eladoknak/bank.svg"
                        alt="bank"
                      />
                    </span>
                  </div>
                  <h3>Banki hitel</h3>
                </div>
                <div class="ld-empty-space"></div>
              </div>

              <div class="column">
                <div class="icon-box">
                  <div class="iconbox-icon-wrap">
                    <span class="iconbox-icon-container">
                      <img
                        class="hover-enlarge"
                        src="../../../assets/img/eladoknak/lizing.svg"
                        alt="lizing"
                      />
                    </span>
                  </div>
                  <h3>Lízing</h3>
                </div>
                <div class="ld-empty-space"></div>
              </div>

              <div class="column">
                <div class="icon-box">
                  <div class="iconbox-icon-wrap">
                    <span class="iconbox-icon-container">
                      <img
                        class="hover-enlarge"
                        src="../../../assets/img/eladoknak/tartozas.svg"
                        alt="tartozas"
                      />
                    </span>
                  </div>
                  <h3>Közüzemi tartozás</h3>
                </div>
                <div class="ld-empty-space"></div>
              </div>

              <div class="column">
                <div class="icon-box">
                  <div class="iconbox-icon-wrap">
                    <span class="iconbox-icon-container">
                      <img
                        class="hover-enlarge"
                        src="../../../assets/img/eladoknak/szolgaltatasidij.svg"
                        alt="szolgaltatasidij"
                      />
                    </span>
                  </div>
                  <h3>Számlatartozás</h3>
                </div>
                <div class="ld-empty-space"></div>
              </div>

              <div class="column">
                <div class="icon-box">
                  <div class="iconbox-icon-wrap">
                    <span class="iconbox-icon-container">
                      <img
                        class="hover-enlarge"
                        src="../../../assets/img/eladoknak/hitel.svg"
                        alt="hitel"
                      />
                    </span>
                  </div>
                  <h3>Áruhitel</h3>
                </div>
                <div class="ld-empty-space"></div>
              </div>

              <div class="column">
                <div class="icon-box">
                  <div class="iconbox-icon-wrap">
                    <span class="iconbox-icon-container">
                      <img
                        class="hover-enlarge"
                        src="../../../assets/img/eladoknak/diakhitel.svg"
                        alt="diakhitel"
                      />
                    </span>
                  </div>
                  <h3>Diákhitel</h3>
                </div>
                <div class="ld-empty-space"></div>
              </div>

              <div class="column">
                <div class="icon-box">
                  <div class="iconbox-icon-wrap">
                    <span class="iconbox-icon-container">
                      <img
                        class="hover-enlarge"
                        src="../../../assets/img/eladoknak/hitelkartyatartozas.svg"
                        alt="hitelkartyatartozas"
                      />
                    </span>
                  </div>
                  <h3>Hitelkártya tartozás</h3>
                </div>
                <div class="ld-empty-space"></div>
              </div>

              <div class="column">
                <div class="icon-box">
                  <div class="iconbox-icon-wrap">
                    <span class="iconbox-icon-container">
                      <img
                        class="hover-enlarge"
                        src="../../../assets/img/eladoknak/kolcson.svg"
                        alt="kolcson"
                      />
                    </span>
                  </div>
                  <h3>Személyi kölcsön</h3>
                </div>
                <div class="ld-empty-space"></div>
              </div>
            </div>
          </div>
        </div>

        <!--    <div style="text-align: center; width: 100%">
          <img
            class="imgkep imagewave"
            src="assets/img/undraw_hello_re_3evm.svg"
            alt="Eladóknak"
          />
        </div> -->
      </div>
    </div>

    <div class="blue-bg">
      <div class="container">
        <div
          style="
            text-align: center;
            width: 100%;
            height: 100%;
            position: sticky;
          "
        >
          <div class="sticky-image-container">
            <img src="assets/img/undraw_choose_re_7d5a.svg" alt="VEVŐKNEK" />
          </div>
        </div>
        <div>
          <div style="text-align: center">
            <p>
              <strong class="fade-up">A TAGSÁG ELŐNYEI ELADÓK SZÁMÁRA</strong>
            </p>
          </div>
          <div class="gridone">
            <p><strong class="fade-up">Széles vevőkör:</strong></p>
            <ul class="margin-ul fade-up">
              <li>
                A regisztrált hitelezők széles körű befektetői bázist
                képviselnek, akik különböző típusú követelések iránt
                érdeklődnek.
              </li>
            </ul>

            <p><strong class="fade-up">Gyors értékesítés:</strong></p>
            <ul class="margin-ul fade-up">
              <li>
                A bonyolult adminisztratív procedúrák mellőzésével - a
                követelések gyorsan és egyszerűen értékesíthetők.
              </li>
            </ul>
            <p><strong class="fade-up">Versenyképes árak:</strong></p>
            <ul class="margin-ul fade-up">
              <li>
                A széles körű licitálási mechanizmusnak köszönhetően - a
                követelések versenyképes áron értékesíthetők.
              </li>
            </ul>
            <p><strong class="fade-up">Biztonságos tranzakciók:</strong></p>
            <ul class="margin-ul fade-up">
              <li>
                Az értékesítési tranzakciók biztonságosan bonyolíthatók le.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="container special">
      <div class="pad">
        <div class="timeline">
          <div class="timeline-item fade-up">
            <div class="timeline-icon">
              <i class="timelineicon-banknote"></i>
            </div>
            <div class="timeline-text">HITELEZŐK</div>
          </div>
          <div class="timeline-item fade-up">
            <div class="timeline-icon">
              <i class="timelineicon-money"></i>
            </div>
            <div class="timeline-text">BANKOK</div>
          </div>
          <div class="timeline-item fade-up">
            <div class="timeline-icon">
              <i class="timelineicon-banknotes"></i>
            </div>
            <div class="timeline-text">HITELSZÖVETKEZETEK</div>
          </div>
          <div class="timeline-item fade-up">
            <div class="timeline-icon">
              <i class="timelineicon-linegraph"></i>
            </div>
            <div class="timeline-text">PÉNZPIACI ALAPOK</div>
          </div>
          <div class="timeline-item fade-up">
            <div class="timeline-icon">
              <i class="timelineicon-banknote"></i>
            </div>
            <div class="timeline-text">BIZTOSÍTÓK</div>
          </div>
          <div class="timeline-item fade-up">
            <div class="timeline-icon">
              <i class="timelineicon-notebook"></i>
            </div>
            <div class="timeline-text">LÍZINGCÉGEK</div>
          </div>
          <div class="timeline-item fade-up">
            <div class="timeline-icon">
              <i class="timelineicon-documents"></i>
            </div>
            <div class="timeline-text">FAKTORCÉGEK</div>
          </div>
          <div class="timeline-item fade-up">
            <div class="timeline-icon">
              <i class="timelineicon-share"></i>
            </div>
            <div class="timeline-text">HITELBIZTOSÍTÓK</div>
          </div>
          <div class="timeline-item fade-up">
            <div class="timeline-icon">
              <i class="timelineicon-pricetags"></i>
            </div>
            <div class="timeline-text">ÖNKORMÁNYZATOK</div>
          </div>
          <div class="timeline-item fade-up">
            <div class="timeline-icon">
              <i class="timelineicon-clipboard"></i>
            </div>
            <div class="timeline-text">KÖZÜZEMI SZOLGÁLTATÓK</div>
          </div>
          <div class="timeline-item fade-up">
            <div class="timeline-icon">
              <i class="timelineicon-graph"></i>
            </div>
            <div class="timeline-text">JOGI SZEMÉLYEK (CÉGEK)</div>
          </div>
          <div class="timeline-item fade-up">
            <div class="timeline-icon">
              <i class="timelineicon-people"></i>
            </div>
            <div class="timeline-text">MAGÁNSZEMÉLYEK</div>
          </div>
        </div>
      </div>
      <div
        style="text-align: center; width: 100%; height: 100%; position: sticky"
      >
        <div class="sticky-image-container">
          <img
            class="imgkep imagewave"
            src="assets/img/Netfaktor_elado_illusztracio_1.svg"
            alt="Eladóknak"
          />
        </div>
      </div>
    </div>
  </div>
</body>
