<body>
  <section class="section">
    <h2 class="section-title fade-up">MŰKÖDÉS</h2>
    <div class="container grid">
      <div class="content">
        <h3 class="fade-up">A Netfaktor működése</h3>
        <p class="padding-left fade-up">
          A Netfaktor egy online platform, amely összeköti a lejárt
          követelésekkel rendelkező eladókat a befektetőkkel és faktorcégekkel.
          A platform célja, hogy megkönnyítse a követelések gyors és hatékony
          értékesítését, és a befektetők számára pedig vonzó befektetési
          lehetőségeket kínáljon.
        </p>
        <br />
        <h3 class="fade-up">Felhasználási feltételek:</h3>
        <p class="padding-left fade-up">
          A rendszerhez kizárólag szerződött/regisztrált partnerek férhetnek
          hozzá. Jelenleg a rendszerhez 20 eladó (bank, közüzem, távközlési
          társaság, stb) és 107 MNB engedéllyel rendelkező faktortársaság fér
          hozzá (akik eladók is lehetnek).
        </p>
        <p class="padding-left fade-up">
          A rendszer használatához internetelérése és böngésző használat
          szükséges.
        </p>
        <br />
        <h3 class="fade-up">Követelések meghirdetése:</h3>
        <p class="padding-left fade-up">
          A hitelezők egyedi követeléseiket és követeléscsomagjaikat is
          meghirdethetik a platformon.
        </p>
        <p class="padding-left fade-up">
          Célszerű Excel táblázatban összesíteni a és közzé tenni követeléseket
          (elektronikus adatszoba) a meghirdetés előtt.
        </p>
        <p class="padding-left fade-up">
          A hitelezők bármilyen releváns adatot feltüntethetnek a
          követelésekről.
        </p>
      </div>
      <div class="image-container">
        <img
          src="assets/img/undraw_business_deal_re_up4u.svg"
          alt="Eladóknak"
        />
      </div>
    </div>
  </section>

  <section class="section blue-bg">
    <div class="container grid">
      <div
        style="text-align: center; width: 100%; height: 100%; position: sticky"
      >
        <div class="sticky-image-container">
          <img src="assets/img/undraw_choose_re_7d5a.svg" alt="Eladóknak" />
        </div>
      </div>
      <div class="content">
        <h3 class="fade-up">Eladói korlátozások:</h3>
        <p class="padding-left fade-up">
          Az eladó korlátozhatja a követeléseihez hozzáférők körét.
        </p>
        <p class="padding-left fade-up">
          Dönthet úgy, hogy egy adott faktor nem ismerheti meg a követeléseit.
        </p>
        <p class="padding-left fade-up">
          Egyedi követelés szintjén is meghatározhatja, kiket hív meg az
          árverésre.
        </p>
        <br />
        <h3 class="fade-up">Értesítések:</h3>
        <p class="padding-left fade-up">
          Az eladó korlátozhatja a követeléseihez hozzáférők körét.
        </p>
        <p class="padding-left fade-up">
          Dönthet úgy, hogy egy adott faktor nem ismerheti meg a követeléseit.
        </p>
        <p class="padding-left fade-up">
          Egyedi követelés szintjén is meghatározhatja, kiket hív meg az
          árverésre.
        </p>
        <br />
        <h3 class="fade-up">Licitálás / Döntéshozatal:</h3>
        <p class="padding-left fade-up">
          A hitelező kiválaszthatja az értékesítési módot (pl. pályázatos
          hirdetés, vaklicit, normál licit, TOP5 licit, japán licit, árejtés,
          stb.).
        </p>
        <p class="padding-left fade-up">
          A licit időtartama szabadon meghatározható.
        </p>
        <p class="padding-left fade-up">
          A licit lépcső beállítható, a rendszer javaslatot tesz a beállítható
          értékekre, de a hitelező szabadon módosíthatja azokat.
        </p>
        <p class="padding-left fade-up">
          A hitelező a beérkezett legmagasabb ajánlat elfogadásáról és
          elutasításáról dönthet. Nincs kötelezettség a legmagasabb ajánlat
          elfogadására és szerződéskötésre.
        </p>
        <br />
        <h3 class="fade-up">Riportkészítés:</h3>
        <p class="padding-left fade-up">
          A Netfaktor minden üzleti információt tárol.
        </p>
        <p class="padding-left fade-up">
          A lezárt követelésekre kapott legjobb ajánlatokat bármikor
          visszakeresheti és ellenőrizheti a hitelező.
        </p>
        <p class="padding-left fade-up">
          A program riportolási és listázási lehetőségei szabadon
          szerkeszthetők.
        </p>
      </div>
    </div>
  </section>
</body>
