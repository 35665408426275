<body>
  <section class="section">
    <h2 class="section-title fade-up">VEVŐKNEK</h2>
    <p class="section-subtitle fade-up">
      A NETFAKTOR online követelésvásárlási platform kizárólag olyan vevőket
      fogad, akik Magyar Nemzeti Bank (MNB) engedéllyel rendelkeznek az
      üzletszerű követelésvásárláshoz.
    </p>
    <p class="section-subtitle fade-up">
      Ezek a vevők lehetnek faktorcégek, befektetők, vagy bármely más olyan jogi
      személy, aki erre jogosult.
    </p>

    <div class="container grid fade-up" style="padding-top: 50px">
      <div class="content">
        <h3 class="fade-up">A NETFAKTOR vevőinek típusai:</h3>
        <h3 class="fade-up">Faktorcégek:</h3>
        <p class="padding-left fade-up">
          A NETFAKTOR vevőinek legnagyobb csoportját a faktorcégek alkotják.
          Ezek a cégek tipikusan egész éven át aktívan vásárolnak követeléseket
          a platformon, akár havonta többször is.
        </p>

        <h3 class="fade-up">Befektetők:</h3>
        <p class="padding-left fade-up">
          A befektetők olyan magánszemélyek vagy jogi személyek, akik a
          NETFAKTOR platformot használják befektetési célból történő
          követelésvásárlásra. A befektetők célja a hozam elérése a követelések
          behajtásával.
        </p>
      </div>
      <div class="image-container">
        <img src="assets/img/undraw_hello_re_3evm.svg" alt="Eladóknak" />
      </div>
    </div>
  </section>

  <section class="section blue-bg">
    <div class="container grid">
      <div
        style="text-align: center; width: 100%; height: 100%; position: sticky"
      >
        <div class="sticky-image-container">
          <img src="assets/img/undraw_choose_re_7d5a.svg" alt="VEVŐKNEK" />
        </div>
      </div>
      <div class="content">
        <h3 class="fade-up">A NETFAKTOR tagság előnyei a vevők számára:</h3>
        <br />
        <h3 class="fade-up">Hozzáférés a piacra:</h3>
        <p class="padding-left fade-up">
          A NETFAKTOR -on a vevők azonnali hozzáférést kapnak a lejárt és nem
          lejárt követelések széles skálájához.
        </p>
        <br />
        <h3 class="fade-up">Hatékony keresés:</h3>
        <p class="padding-left fade-up">
          A fejlett keresési funkciói lehetővé teszik a vevők számára, hogy
          gyorsan és egyszerűen megtalálják a kívánt követeléseket.
        </p>
        <br />
        <h3 class="fade-up">Átláthatóság:</h3>
        <p class="padding-left fade-up">
          A NETFAKTOR átlátható árazást és tranzakciós folyamatot biztosít a
          vevők számára.
        </p>
        <br />
        <h3 class="fade-up">Kategorizálás:</h3>
        <p class="padding-left fade-up">
          A követelések kategorizálva és strukturált formában kerülnek
          bemutatásra, ami megkönnyíti a vevők számára a keresést és az
          összehasonlítást.
        </p>
        <br />
      </div>
    </div>
    <p class="lasttext fade-up">
      <span class="lasttext">
        Ha Ön MNB engedéllyel rendelkező vevő, és szeretne a NETFAKTOR
        platformon követeléseket vásárolni, kérjük, vegye fel a kapcsolatot
        munkatársainkkal
      </span>
    </p>
  </section>
</body>
