<div class="header">
    <a routerLink="/">
        <img class="netfaktorlogo" src="assets/img/logo-header.svg" alt="Netfaktor">
    </a>
    <button class="hamburger hamburger--spin" type="button" [class.is-active]="mobmenu" (click)="mobilemenu()">
        <span class="hamburger-box">
            <span class="hamburger-inner"></span>
        </span>
    </button>
    <div class="navigationcontainer" [class.show]="mobmenu">
        <app-navmenu (click)="mobmenu = false"></app-navmenu>
        <div class="staticlinks">
            <a href="https://app.proexchange.hu/netfaktor/" class="btn">Belépés</a>
        </div>
       <!--  <app-select-language></app-select-language> -->
    </div>
</div>