import { Component, ElementRef, AfterViewInit } from "@angular/core";

@Component({
  selector: "app-kapcsolat",
  templateUrl: "./tovabbivevo.component.html",
  styleUrls: ["./tovabbivevo.component.css"],
})
export class TovabbiVevoComponent implements AfterViewInit {
  titlefromParent = "Kérdése van?";

  workers = [
    {
      name: "Fekete Zsolt",
      pos: "ügyvezető",
      email: "feketezs@netfaktor.hu",
      phone: "+36 20/495 1395",
    },
  ];

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    const elements = this.el.nativeElement.querySelectorAll(".fade-up");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show");
          }
        });
      },
      { threshold: 0.2 }
    );

    elements.forEach((element) => observer.observe(element));
  }
}
